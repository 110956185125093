import { getUrlParts, isStandalone } from '@digital-retail/store-manager';
import { isWindow } from '../../utils/deviceChecker';

const countryMap = {
  cl: 'FACL',
  co: 'FACO',
  pe: 'FAPE',
  ar: 'FAAR',
};

export const getStoreUrl = (host) => {
  const storeFilter = ['sodimac', 'tottus', 'linio', 'tienda', 'homecenter'].filter((store) => host.includes(store));

  if (/.sodimac.cl|.sodimac.com/.test(host)) {
    return host.replace(/.sodimac.cl|.sodimac.com/, '.falabella.com');
  }

  return storeFilter.length > 0 ? host.replace(`-${storeFilter}`, '').replace(`${storeFilter}`, 'www') : host;
};

export default {
  taxonomyURL: ({ base, regionCode }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/v2/taxonomy/${regionCode || 'cl'}`;
  },
  getProductsUrl({ base, siteId, rest }) {
    return `${base}/v1/search/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getListingsUrl({ base, siteId, rest }) {
    return `${base}/v1/listing/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getCollectionUrl({ base, siteId, rest }) {
    return `${base}/v1/collection/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getHeaderUrl: ({ base, regionCode, site }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/v3/header/${regionCode || 'cl'}?site=${site}`;
  },
  getSISNavigationMenuURL: ({ base, regionCode, version = 'v2', store, site }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    // eslint-disable-next-line no-underscore-dangle
    const _site = site ? `site=${site}` : '';
    return `${base}/${version}/sisMenu/${regionCode || 'cl'}/${store}?${_site}`;
  },
  getFooterUrl: ({ base, regionCode, version = 'v2', site }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/footer/${regionCode || 'cl'}?site=${site}`;
  },
  getTransversalBannerUrl: ({ base, regionCode }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/v3/transversal/${regionCode || 'cl'}`;
  },
  getCommerceAnalyticsInfoUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    return `${host}/s/account/v1/analytics-info`;
  },
  getLocationsUrl: ({ base, siteId }) => {
    return `${base}/v1/zones/${siteId}`;
  },
  getProductDetailsUrl: ({ base, siteId, params }) => {
    return `${base}/v2/product/${siteId}?${Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  },
  getConnectPlansUrl: ({ base, siteId, params }) => {
    return `${base}/v1/plans/${siteId}?${Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  },
  getConnectTermsAndConditions: (staticRoute) => {
    return `https://assets.contentstack.io${staticRoute}`;
  },
  getLoggingUrl: () => {
    return `/log`;
  },
  getBreadcrumbUrl: (regionCode, link) => {
    return `/falabella-${regionCode}${link}`;
  },
  getImagesURL: (regionCode) => {
    let fa = 'Falabella';
    switch (regionCode) {
      case 'co': {
        fa = 'FalabellaCO';
        break;
      }
      case 'ar': {
        fa = 'FalabellaAR';
        break;
      }
      case 'pe': {
        fa = 'FalabellaPE';
        break;
      }
      default: {
        fa = 'Falabella';
      }
    }
    return `https://falabella.scene7.com/is/image/${fa}`;
  },
  getCFImagesURL: (regionCode, store, isImageDeliveryOverrideEnabled) => {
    const imageUrls = {
      cl: 'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaCL',
      pe: 'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaPEAndCO',
      co: 'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaPEAndCO',
    };

    const image = imageUrls[regionCode] || imageUrls.cl;
    if (isImageDeliveryOverrideEnabled) {
      let host;
      if (isWindow()) {
        host = window.location.origin;
      } else {
        host = getUrlParts({ regionCode, store }).origin;
      }

      return image.replace('https://imagedelivery.net', `${host}/cdn-cgi/imagedelivery`);
    }

    return image;
  },
  getBackPDP: (regionCode, productId) => {
    return `/falabella-${regionCode}/product/${productId}`;
  },
  imagesBaseURL: 'https://falabella.scene7.com/is/image',
  getCanonicalUrl: ({ regionCode, searchTerm, store }) => {
    const { href } = getUrlParts({ regionCode, store });
    return `${href}/${isStandalone({ store }) ? 'buscar' : 'search'}?Ntt=${searchTerm}`;
  },
  getFacetsUrl({ base, siteId, params }) {
    return `${base}/v1/search/${siteId}?${params}&fonly=true`;
  },
  getFacetsUrlForCategory({ base, siteId, params }) {
    return `${base}/v1/listing/${siteId}?${params}&fonly=true`;
  },
  getFacetsUrlForCollection({ base, siteId, params }) {
    return `${base}/v1/collection/${siteId}?${params}&fonly=true`;
  },
  getFacetsUrlForSeller({ base, siteId, params, sellerName }) {
    const encodedSeller = encodeURIComponent(sellerName);
    return `${base}/v1/seller/${siteId}?${params}&sellerName=${encodedSeller}&fonly=true`;
  },
  getFacetsUrlForBrand({ base, siteId, params, brandName }) {
    const encodedBrand = encodeURIComponent(brandName);
    return `${base}/v1/brand/${siteId}?${params}&brandName=${encodedBrand}&fonly=true`;
  },
  getFacetValues({ base, siteId, params }) {
    return `${base}/v1/facet/${siteId}?${Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  },
  getSelectedFacetValues({ base, siteId, params }) {
    return `${base}/v1/facet/${siteId}?${params}`;
  },
  addToCartCommerceUrl: (query, regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    return `${hostUrl}/s/checkout/v1/carts${query}`;
  },
  addToListCommerceUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    return `${hostUrl}/s/checkout/v1/lists`;
  },
  getAllListsCommerceUrl: (listType, regionCode, politicalId) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    const url = new URL(`${hostUrl}/s/checkout/v2/lists?listType=${listType}&politicalAreaId=${politicalId}`);
    url.searchParams.set('includeLines', 'true');
    return url;
  },
  deleteFromListCommerceUrl: (listId, listLineItemId, regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    return `${hostUrl}/s/checkout/v1/lists/${listId}/list-lines/${listLineItemId}`;
  },
  patchCartlineCommerceUrl: (cartId, regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    return `${hostUrl}/s/checkout/v1/carts/${cartId}/cart-lines`;
  },
  removeCartlineCommerceUrl: (cartId, regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    return `${host}/s/checkout/v1/carts/${cartId}/remove-items`;
  },
  deleteCartlineCommerceUrl: (cartId, cartLineItemId, regionCode) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    return `${host}/s/checkout/v1/carts/${cartId}/cart-lines/${cartLineItemId}`;
  },
  getBrandApiURL({ base, siteId, params }) {
    return `${base}/v1/brand/${siteId}?${Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  },
  getSellerApiURL({ base, siteId, params }) {
    return `${base}/v1/seller/${siteId}?${Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')}`;
  },
  getSizeCharturl: (url, regionCode) => {
    const host = getUrlParts({ regionCode }).origin;
    return url[0] === '/' ? `${host}${url}` : url;
  },
  formatRelativeUrl: (url, regionCode) => {
    const host = getUrlParts({ regionCode }).origin;
    return `${host}${url}`;
  },
  getEnvironmentSpecificUrl: (url, env) => {
    return url.replace('www', env);
  },
  getSizeInfoURL: (isNewSizeGroupService, regionCode, productId, sizes, backendCategoryId, webCategoryId, brandId) => {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const country = countryMap[regionCode];

    const sizeStr = encodeURIComponent(sizes.join(','));
    const path = isNewSizeGroupService ? '/browse' : '';
    return `${host}/s${path}/size/size-info?id=${productId}&country=${country}&sizes=${sizeStr}&backendCategory=${backendCategoryId}&webCategory=${webCategoryId}&brandId=${encodeURIComponent(
      brandId
    )}`;
  },
  getProductLiteDetailsUrl({ regionCode, rest }) {
    const host = isWindow() ? window.location.origin : getUrlParts({ regionCode }).origin;
    const hostUrl = getStoreUrl(host);
    return `${hostUrl}/s/browse/v1/product/${regionCode}/lite?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getGeoUrl: ({ base, siteId }) => {
    return `${base}/v2/states/${siteId}`;
  },
  mabayaVisitUrl: ({ storeHash, sku, referrerSignal, referrerURL, referrerType, cookie }) => {
    const url = new URL('https://us.evt.mb-srv.com/v2/server2server/visit');
    const params = {
      storeHash,
      sku,
      referrerSignal,
      referrerURL,
      ReferrerType: referrerType,
    };
    if (cookie) params.cookie = cookie;
    url.search = new URLSearchParams(params).toString();

    return url.toString();
  },
  mabayaClickUrl: ({ storeHash, platform, adInfo, mPos, cookie }) => {
    return `https://us.widget.mb-srv.com/v2/server2server/click?storeHash=${storeHash}&cookie=${cookie}&platform=${platform}&mabaya_ad_info=${adInfo}&mPos=${mPos}`;
  },
  mabayaImpressionUrl: ({ storeHash, platform, adInfo, mPos }) => {
    return `https://us.widget.mb-srv.com/v2/server2server/imp?storeHash=${storeHash}&platform=${platform}&mabaya_ad_info=${adInfo}&mPos=${mPos}`;
  },
  getDistrictInfo: ({ base, siteId, politicalId }) => {
    return `${base}/v2/districts/${siteId}?politicalId=${politicalId}`;
  },
  getGoogleMapLink: ({ lat, lng }) => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  },
  getLowestPlanUrl: ({ regionCode, rest }) => {
    const host = getUrlParts({ regionCode }).origin;
    return `${host}/s/browse/v1/search/${regionCode}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  sponsoredAdsUrl: (baseUrl) => `${baseUrl}/v1/vas/ads`,
  sponsoredClickUrl: (baseUrl) => `${baseUrl}/v1/vas/click`,
  sponsoredImpressionUrl: (baseUrl) => `${baseUrl}/v1/vas/impression`,
  sponsoredVisitUrl: (baseUrl) => `${baseUrl}/v1/vas/visit`,
};
